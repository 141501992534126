var floatOrZero = function (val) {
    return isFinite(parseFloat(val || 0.00)) ? parseFloat(val || 0.00) : 0.00;
};
export default {
    data: function () {
        var self = this;
        return {
            filasPendientesActualizar: [],
            fields: ['wt', 'tipo_impuesto', 'ivap', 'rep'],
            beforeSave: function (resolve, reject) {
                var self = this;
                self.actualizar_total_unidades();
                self.actualizar_total_peso();
                self.actualizar_total_articulos();
                self.actualizar_g18k();
                self.actualizar_gmerma();
                self.actualizar_gcontraste_porcentaje();
                self.actualizar_gotroscostes_porcentaje();
                self.actualizar_costes_adicionales();
                if (!self.formData.lineas || !self.formData.lineas.length) {
                    reject("Debe introducir alguna línea de pedido");
                    return;
                }
                if (self.formData.lineas.some(l => l.c == 'P' && !parseFloat(l.peso || 0))) {
                    reject("Una compra a peso no puede tener líneas sin peso");
                    return;
                }
                if (self.formData.lineas.some(l => l.c == 'U' && !parseFloat(l.unidades || 0))) {
                    reject("Una compra a unidad no puede tener líneas sin unidades");
                    return;
                }
                resolve(self.formData);
            },
        }
    },
    methods: {
        mostrarComentariosProveedor() {
            let self = this;
            let v = self.formData.proveedor_id;
            if (!v.comentarios || !v.comentarios.length) {
                self.app.toast('El proveedor no tiene comentarios activos', 'info');
                return;
            }
            var strComentarios = '';
            (v.comentarios || []).forEach(com => {
                if (parseInt(com.activo)) {
                    strComentarios += self.$utils.misc.formatDate(com.fecha) + ': ' + com.descripcion + '\n';
                }
            });
            if (strComentarios) self.app.toast(strComentarios, 'info');
        },
        abrirSaldos() {
            let v = this.formData.proveedor_id;
            this.app.openWindow('proveedor', {
                itemId: v.codigo
            });
        },
        actualizar_otroscosteslinea() {
            var self = this;
            if (!self.$refs.otroscostes) return;
            let h = self.$refs.lineas.field.hotInstance;
            let h_oc = self.$refs.otroscostes.field.hotInstance;
            //let val = (columna) => h.getDataAtRowProp(row, columna) || 0;
            var sum_pcoste = 0;
            h.getSourceData().forEach(function (item) {
                if (item && item['precio_coste']) {
                    sum_pcoste += (item['precio_coste'] || 0) * (item['unidades'] || 0);
                }
            });
            var sum_ocostes = 0;
            var arr_l = h_oc.getDataAtProp('l');
            h_oc.getDataAtProp('impdivisabase').forEach(function (item, i) {
                if (arr_l[i] == 1) {
                    sum_ocostes += (parseFloat(item) || 0);
                }
            });
            h.getSourceData().forEach(function (item, i) {
                if (item && item['precio_coste']) {
                    var otros_costes_linea = (item['precio_coste'] / sum_pcoste) * sum_ocostes;
                    if (!isFinite(otros_costes_linea)) otros_costes_linea = 0;
                    //h.setDataAtRowProp(i, 'otros_costes', otros_costes_linea, 'auto');
                    h.getSourceData()[i].otros_costes = otros_costes_linea.toFixed(2);
                    self.actualizar_pventa(i);
                }
            });
            //h.render();
            self.renderH(h);
        },

        actualizar_costes_adicionales() {
            var self = this;
            if (!self.$refs.otroscostes) return;
            let h_oc = self.$refs.otroscostes.field.hotInstance;
            var t = 0;
            let arr_p = h_oc.getDataAtProp('p');
            h_oc.getDataAtProp('impdivisa').forEach(function (item, i) {
                if (item && parseInt(arr_p[i])) { t += floatOrZero(item); }
            });
            self.$set(self.formData, 'costes_adicionales', t);
            self.$emit("change", "costes_adicionales", "auto");
        },
        actualizar_totallinea(row) {
            var self = this;
            let h = self.$refs.lineas.field.hotInstance;
            let val = (columna) => h.getDataAtRowProp(row, columna) || 0;
            if (val('c') == 'P') {
                var totallinea = val('precio') * (1 - val('descuentos') * 0.01) * val('peso');
            } else {
                totallinea = val('precio') * (1 - val('descuentos') * 0.01) * val('unidades');
            }
            h.getSourceData()[row].totallinea = totallinea.toFixed(2);
            //h.render();
            self.renderH(h);
            //self.actualizar_total_articulos();
        },
        actualizar_pventa(row, recalcular) {
            var self = this;
            recalcular = recalcular || self.formData.recalcular;
            let h = self.$refs.lineas.field.hotInstance;
            let val = (columna) => floatOrZero(h.getDataAtRowProp(row, columna));
            if (recalcular == 'precio_venta') {
                var precio_venta = (1 + (parseFloat(val('margen')) / 100)) * (parseFloat(val('precio_coste')) + parseFloat(val('coste_fijo')) + (parseInt(val('incluir_taqueria')) ? parseFloat(val('taqueria_precio')) : 0) + parseFloat(val('otros_costes')));
                //h.setDataAtRowProp(row, 'pventa', precio_venta, 'auto');
                h.getSourceData()[row].pventa = precio_venta.toFixed(2);
            } else {
                var margen = 100 * (val('pventa') / (val('precio_coste') + val('coste_fijo') + val('taqueria_precio') + val('otros_costes')) - 1);
                if (!isFinite(margen)) margen = 0;
                //h.setDataAtRowProp(row, 'margen', margen, 'auto');
                h.getSourceData()[row].margen = margen.toFixed(2);
            };
            //h.render();
            self.renderH(h);
        },
        actualizar_preciocoste(row) {
            var self = this;
            let h = self.$refs.lineas.field.hotInstance;
            let val = (columna) => h.getDataAtRowProp(row, columna) || 0;
            var divisa = parseFloat(self.formData.cambio) || 0;
            var prmetal = parseFloat(self.formData.prmetalp || 0);
            var factor = parseFloat(self.formData.factor || 0);
            var contraste = parseFloat(self.formData.contraste || 0);
            var ocostes = parseFloat(self.formData.ocostes || 0);
            var mermaporlinea = parseInt(self.formData.mermaporlinea || 0);
            var merma = mermaporlinea ? parseFloat(val('mpl')) : parseFloat(self.formData.merma || 0);
            if (parseInt(val('usarpmp'))) {
                var precio = val('pmp') * divisa;
            } else {
                precio = val('precio');
            }
            var dto = parseFloat(val('descuentos'));
            var unidades = parseFloat(val('unidades'));
            var peso = parseFloat(val('peso'));
            var c2 = parseInt(val('c2'));
            var o = parseInt(val('o'));
            var precio_coste = 0;

            /*1*/
            if (val('c') == 'U' && val('v') == 'U' && !parseInt(val('m')) && !parseInt(val('m3'))) {
                precio_coste = (precio * (1 - dto / 100) + ((contraste * c2 + ocostes * o) * (peso / unidades))) / divisa;
                /*2*/
            } else if (val('c') == 'U' && val('v') == 'U' && !parseInt(val('m')) && parseInt(val('m3'))) {
                //var precio_coste = (((precio*(1-dto/100))*unidades + peso*(prmetal*factor)*(1+merma/100))/unidades)/divisa;
                precio_coste = (((precio * (1 - dto / 100)) * unidades + peso * (prmetal * factor) * (1 + merma / 100)) / unidades + ((contraste * c2 + ocostes * o) * peso / unidades)) / divisa;
                /*3*/
            } else if (val('c') == 'P' && val('v') == 'U' && !parseInt(val('m')) && !parseInt(val('m3'))) {
                //var precio_coste = ((precio*(1-dto/100)*peso)/unidades)/divisa;
                precio_coste = ((((precio * (1 - dto / 100) * peso) / unidades) + (contraste * c2 + ocostes * o) * (peso / unidades))) / divisa;
                /*4*/
            } else if (val('c') == 'P' && val('v') == 'P' && !parseInt(val('m')) && !parseInt(val('m3'))) {
                //var precio_coste = ((precio*(1-dto/100)*peso)/peso)/divisa;
                precio_coste = (((precio * (1 - dto / 100) * peso)) / peso + (contraste * c2 + ocostes * o)) / divisa;
                /*5*/
            } else if (val('c') == 'P' && val('v') == 'U' && !parseInt(val('m')) && parseInt(val('m3'))) {
                //var precio_coste = ((precio*(1-dto*0.01)*peso)+(peso*((prmetal*factor)*(1+merma/100))/divisa)/unidades)/unidades;
                precio_coste = ((precio * (1 - dto * 0.01) * peso) + (peso * ((prmetal * factor) * (1 + merma / 100)))) / unidades / divisa + ((contraste * c2 + ocostes * o) * peso / (divisa * unidades));
                /*6*/
            } else if (val('c') == 'P' && val('v') == 'P' && !parseInt(val('m')) && parseInt(val('m3'))) {
                //var precio_coste = (((precio*(1-dto*0.01))+(((prmetal*factor)*(1+merma/100))/divisa))+((((contraste+ocostes))))/divisa);
                precio_coste = (((precio * (1 - dto * 0.01)) + (((prmetal * factor) * (1 + merma / 100)))) / divisa) + ((contraste * c2 + ocostes * o) / (divisa));
                /*7*/
            } else if (val('c') == 'U' && val('v') == 'U' && parseInt(val('m')) && parseInt(val('m3'))) {
                precio_coste = ((((precio * (1 - dto / 100) * unidades) / unidades) + (ocostes * o * (peso / unidades))) / divisa);
                /*8*/
            } else if (val('c') == 'P' && val('v') == 'U' && parseInt(val('m')) && parseInt(val('m3'))) {
                precio_coste = ((((precio * (1 - dto / 100) * peso) / unidades) + (ocostes * o * (peso / unidades))) / divisa);
                /*9*/
            } else if (val('c') == 'P' && val('v') == 'P' && parseInt(val('m')) && parseInt(val('m3'))) {
                precio_coste = ((((precio * (1 - dto / 100) * peso)) / peso + ocostes * o)) / divisa;
            }
            if (!isFinite(precio_coste)) {
                precio_coste = 0;
            }
            //h.setDataAtRowProp(row, 'precio_coste', precio_coste, 'auto');
            h.getSourceData()[row].precio_coste = precio_coste.toFixed(3);
            //h.render();
            self.actualizar_otroscosteslinea();
            self.renderH(h);
        },
        tieneRecargoEquivalencia() {
            let self = this;
            return floatOrZero(self.app.empresa.recargo_equivalencia);
        },

        actualizar_pmp(row) { } //TODO
    },
    mounted() {
        var self = this;

        //Al seleccionar el proveedor
        self.$refs.proveedor_id.field.$on('select', function () {
            if (self.mode != "new" && self.mode != "edit") return;
            if (!self.formData.proveedor_id) return;
            // Cargar datos del proveedor
            var v = self.formData.proveedor_id;
            self.$set(self.formData, 'formapago_id', v.forma_pago_id);
            self.$set(self.formData, 'importacion', v.nacionalidad);
            self.$set(self.formData, 'dto_especial_porcentaje', floatOrZero(v.descuento_especial).toFixed(2));
            self.$set(self.formData, 'financiacion_porcentaje', -v.financiacion);
            self.$set(self.formData, 'ivap', v.impuesto_id && v.impuesto_id.valor);
            self.$set(self.formData, 'rep', floatOrZero(self.app.config.recargo_equivalencia));
            self.$set(self.formData, 'divisa_id', v.divisa_id);
            self.$set(self.formData, 'cambio', floatOrZero(v.divisa_id.ratio).toFixed(2));
            self.$set(self.formData, 'tipo_impuesto', v.impuesto_id && v.impuesto_id.nombre);
            self.$set(self.formData, 'merma', v.merma);
            self.$set(self.formData, 'mermaporlinea', v.merma_linea);
            self.refrescarColumnaMPL(true);
            self.$emit('change', 'formapago_id', 'auto');
            self.$emit('change', 'dto_especial_porcentaje', 'auto');
            self.$emit('change', 'financiacion_porcentaje', 'auto');
            self.$emit('change', 'ivap', 'auto');
            self.$emit('change', 'rep', 'auto');
            self.$emit('change', 'cambio', 'auto');
            if (v.comentarios.length) self.mostrarComentariosProveedor();
        });

        //Actualizar precio_coste al cambiar contraste, merma, otroscostes o prmetalp
        self.onChange(['contraste', 'ocostes', 'ocostesu', 'merma', 'prmetalp', 'factor', 'mermaporlinea'], function (source) {
            let h = self.$refs.lineas.field.hotInstance;
            h.getSourceData().forEach(function (item, i) {
                if (item['articulo_id']) self.actualizar_preciocoste(i); //TODO: optimizar, source=many
            });
        });

        //Al cambiar otroscostes
        if (self.$refs.otroscostes) {
            self.$refs.otroscostes.field.hotInstance.addHook('beforeChange', function (changes, source) {
                if (!changes) return;
                if (source == 'loadData') return;
                if (!self.formData.cambio) {
                    self.app.toast('Introduce la divisa e indica el cambio', 'error');
                    return false;
                }
            });
            self.$refs.otroscostes.field.hotInstance.addHook('afterChange', function (changes, source) {
                if (!changes) return;
                if (source == 'loadData') return;
                var h_oc = this;
                var divisa = floatOrZero(self.formData.cambio);
                changes.forEach(function (change) {
                    if (!change) return;
                    var row = change[0];
                    var prop = change[1];
                    var oldVal = change[2];
                    var newVal = change[3];
                    // Cargar coste adicional a partir de código
                    if (prop == 'c' && oldVal != newVal && source != 'auto') {
                        if (!newVal) {
                            h_oc.setDataAtRowProp([
                                //[row, 'descripcion', null],
                                [row, 'impdivisabase', null],
                                [row, 'impdivisa', null],
                                [row, 'p', null],
                                [row, 'l', null],
                            ], 'auto');
                            return;
                        }
                        window.DB.get('coste_adicional', {
                            itemId: newVal,
                            fields: ['descripcion', 'precio_predeterminado']
                        }).then(function (data) {
                            h_oc.setDataAtRowProp([
                                //[row, 'descripcion', data.descripcion],
                                [row, 'impdivisabase', data.precio_predeterminado]
                            ], 'auto');
                        }).catch(function (res) {
                            h_oc.selectCell(row, prop);
                            h_oc.setDataAtRowProp(row, 'c', null, 'auto');
                            self.app.toast(res.error);
                        });
                    }
                    if ((prop == 'impdivisabase' || prop == 'l') && oldVal != newVal) {
                        self.actualizar_otroscosteslinea();
                    }
                    if (prop == 'impdivisabase' && oldVal != newVal) {
                        if (!isFinite(newVal)) return;
                        h_oc.setDataAtRowProp(row, 'impdivisa', newVal * divisa, 'auto'); //TODO: change divisa y eliminar fila
                    }
                    if (prop == 'impdivisa' && oldVal != newVal) {
                        if (!isFinite(newVal)) return;
                        h_oc.setDataAtRowProp(row, 'impdivisabase', newVal / divisa, 'auto'); //TODO: change divisa y eliminar fila
                        //APP.LOGIC.factura_proveedor.calc.actualizar_costes_adicionales(obj); // TODO
                    }
                    if (prop == 'c' && oldVal != newVal) {
                        h_oc.setDataAtRowProp([
                            [row, 'p', 1],
                            [row, 'l', 1]
                        ], 'auto');
                    }
                });
            });
        }

        self.$refs.lineas.field.$on("removeRow", function () {
            self.actualizar_otroscosteslinea();
            /*self.actualizar_total_unidades();
            self.actualizar_total_peso();
            self.actualizar_total_articulos();
            self.actualizar_g18k();
            //self.actualizar_g24k_porcentaje(); //Lo hace con lo anterior
            self.actualizar_gmerma();
            self.actualizar_taqueria();
            self.actualizar_estadisticas_familias();*/
        });

        var acumulado = '';
        var guardado = {};
        var h = self.$refs.lineas.field.hotInstance;
        let borrarAcumulado = self.$utils.misc.debounce(() => acumulado = '', 50)
        self.$refs.lineas.$el.addEventListener('keydown', function (e) {
            let sel = h.getSelectedLast();
            if (sel && h.colToProp(sel[1]) == 'ean13') {
                borrarAcumulado();
                if (e.key == 'Enter') {
                    let row = sel[0];
                    guardado[row] = acumulado;
                    acumulado = '';
                } else if (e.keyCode > 47 && e.keyCode < 58) {
                    acumulado += e.key;
                }
            }
        });

        self.$refs.lineas.field.hotInstance.addHook('beforeChange', function (changes, source) {
            if (!changes) return;
            changes.forEach(function (change, index) {
                if (!change) return;
                let row = change[0];
                var prop = change[1];
                var newVal = change[3];

                var x = guardado[row];
                if (prop == 'ean13' && newVal && x && x.length > newVal.length) {
                    changes[index][3] = x;
                    guardado[row] = '';
                }
            });
        });

        self.$refs.lineas.field.hotInstance.addHook('afterChange', function (changes, source) {
            if (source == 'loadData') return;
            if (!changes) return;
            var h = this;
            var divisa = floatOrZero(self.formData.cambio)
            changes.forEach(function (change, index) {
                if (!change) return;
                var row = change[0];
                var prop = change[1];
                var oldVal = change[2];
                var newVal = change[3];

                var val = (columna) => h.getDataAtRowProp(row, columna) || 0;
                if (((prop == 'ean13' || prop == 'articulo_id') && oldVal != newVal && source != 'auto') || source == 'newproduct') {
                    if (prop == 'ean13' && newVal in self.cachedArticulosEan13) {
                        var p = Promise.resolve({
                            count: 1,
                            data: [self.$utils.misc.clone(self.cachedArticulosEan13[newVal])]
                        });
                    } else if (prop == 'articulo_id' && newVal in self.cachedArticulosCodigo) {
                        p = Promise.resolve({
                            count: 1,
                            data: [self.$utils.misc.clone(self.cachedArticulosCodigo[newVal])]
                        });
                    } else {
                        if (prop == 'ean13' && newVal.length == 12) newVal = '0' + newVal;
                        if (prop == 'ean13' && newVal.includes(',')) {
                            newVal = parseInt(newVal.replace('.', '')).toString();
                            newVal = newVal.padStart(7, '0');
                            const ean_checkdigit = (code) => {
                                let sum = 0;
                                for (let i = 0; i < code.length; i++) {
                                    sum += (((i + 1) % 2) * 2 + 1) * code[code.length - 1 - i];
                                }
                                return code + (10 - (sum % 10)) % 10;
                            }
                            newVal = ean_checkdigit(newVal);
                            window.console.log(newVal);
                        }
                        p = window.DB.getList('articulo', {
                            limit: 1,
                            filter: [prop == 'articulo_id' ? 'codigo' : 'ean13', "=", newVal],
                            depth: 3,
                            fields: [
                                'codigo',
                                'ean13',
                                { name: 'modelo_id', fields: ['codigo', 'nombre'] },
                                { name: 'familia_id', fields: ['codigo', 'nombre'] },
                                { name: 'tipo_comision_id', fields: ['codigo', 'descripcion'] },
                                'nombre',
                                'codigo_proveedor',
                                'unidad_medida_compra',
                                'compra_metal_incluido',
                                'recargo_equivalencia',
                                'incluir_contraste_pcoste',
                                'incluir_ocostes_pcoste',
                                'precio_coste',
                                'unidad_medida_venta',
                                'venta_metal_incluido',
                                'peso_unitario_fino',
                                'margen_ultima_compra',
                                { name: 'taqueria_ids', fields: ['codigo', 'nombre', { name: 'thumbnail', fields: ['imagen'] }, 'precio_coste'] },
                                'taqueria_precio',
                                { name: 'thumbnail', fields: ['imagen'] },
                                'precio_coste_hechura_euros'
                            ]
                        });
                    }
                    p.then(function (res) {
                        if (parseInt(res.count)) {
                            let a = res.data[0];
                            self.cachedArticulosCodigo[a.codigo] = a;
                            self.cachedArticulosEan13[a.ean13] = a;
                            //h.cachedItems['articulo_id']

                            let d = h.getSourceData()[row];
                            d.articulo_id = a;
                            d.ean13 = a.ean13;
                            d.modelo_id = a.modelo_id;
                            //d.familia_id = a.familia_id.codigo;
                            d.descripcion = a.nombre;
                            d.refproveedor = a.codigo_proveedor;
                            d.mpl = (self.formData.merma || 0);
                            d.c = a.unidad_medida_compra == 'peso' ? 'P' : 'U';
                            d.m = parseInt(a.compra_metal_incluido) ? 1 : 0;
                            d.r = a.recargo_equivalencia || 0;
                            d.m2 = (parseInt(a.compra_metal_incluido) ? 0 : 1);
                            d.c2 = (parseInt(a.incluir_contraste_pcoste) ? 1 : 0);
                            d.o = (parseInt(a.incluir_ocostes_pcoste) ? 1 : 0);
                            d.coste_fijo = 0;
                            d.precio = floatOrZero(a.precio_coste_hechura_euros * divisa);
                            d.v = a.unidad_medida_venta == 'peso' ? 'P' : 'U';
                            d.m3 = (parseInt(a.venta_metal_incluido) ? 1 : 0);
                            d.unidades = 1;
                            d.etiqueta = 1;
                            d.peso = a.peso_unitario_fino || 0;
                            d.margen = floatOrZero(a.margen_ultima_compra).toFixed(2);
                            d.pventa = 0;
                            d.taqueria_ids = a.taqueria_ids;
                            d.incluir_taqueria = 1;
                            d.taqueria_precio = (a.taqueria_ids || []).reduce((tot, sum) => tot + floatOrZero(sum.precio_coste), 0);
                            d.almacen_id = self.app.config.almacen_defecto || null;
                            d.descuentos = 0;
                            d.t = 0;

                            h.render();
                            //TODO? trigger afterChange rHandsontable?
                            self.filasPendientesActualizar.push(row);
                            self.actualizarTodo();

                            if (parseInt(self.app.config['mostrar_imagen_' + self.dbAdapter])) {
                                if (self.win.children.length && self.win.children.some(ch => ch.window_name == 'popup_imagen')) {
                                    let popupImagen = self.win.children.find(ch => ch.window_name == 'popup_imagen');
                                    if (popupImagen.instance) popupImagen.instance.articulo = a;
                                    else popupImagen.opts.articulo = a;
                                } else {
                                    self.app.openChildWindow('popup_imagen', self.win, {
                                        backdrop: false,
                                        resizable: true,
                                        articulo: a
                                    })
                                }
                            }
                        } else {
                            h.setDataAtRowProp(row, prop, oldVal, 'auto');
                            if (prop == 'articulo_id') {
                                if (!(self.mode == 'edit' || self.mode == 'new')) {
                                    self.app.toast('Debes pulsar el botón Editar o Nuevo', 'error')
                                } else {
                                    h.deselectCell();
                                    self.app.openChildWindow('articulo_quickview', self.win, {
                                        backdrop: true,
                                        resizable: false,
                                        props: {
                                            initialMode: 'new',
                                            initialFormData: {
                                                codigo: newVal,
                                                modelo_id: h.getSourceData()[row]['modelo_id'] || null
                                            },
                                            onSave(id, data) {
                                                h.setDataAtRowProp(row, 'articulo_id', id, 'newproduct');
                                                this.$emit('close');
                                            },
                                            onCancel() {
                                                this.$emit('close');
                                            }
                                        }
                                    })
                                }
                            }
                            //h.selectCell(row, prop);
                        }
                    }).catch(function (res) {
                        window.console.log(res);
                    });
                }

                //Calcular Margen y Pventa
                if ((prop == 'precio_coste' || prop == 'coste_fijo' || prop == 'incluir_taqueria' || prop == 'taqueria_precio' || prop == 'otros_costes') && oldVal != newVal) {
                    self.actualizar_pventa(row);
                }
                if ((prop == 'margen') && oldVal != newVal && source != 'auto') {
                    self.actualizar_pventa(row, 'precio_venta');
                }
                if ((prop == 'pventa') && oldVal != newVal && source != 'auto') {
                    self.actualizar_pventa(row, 'margen');
                };
                //Unidades: 1, Etiq: 1 y recalcular peso
                if (prop == 'unidades' && oldVal != newVal) {

                    var etiqueta = Math.ceil(newVal);
                    h.getSourceData()[row].etiqueta = etiqueta;
                    //h.setDataAtRowProp(row, 'etiqueta', etiqueta, 'auto');
                    if (oldVal) {
                        var peso = newVal * val('peso') / oldVal;
                    } else {
                        peso = 0;
                    }
                    h.getSourceData()[row].peso = peso;
                    self.actualizar_totallinea(row);
                    self.actualizar_preciocoste(row);
                    self.actualizar_pventa(row);
                    /*self.actualizar_total_peso();
                    self.actualizar_g18k();
                    self.actualizar_gmerma();*/

                }
                //Al marcar el campo Metal en compra
                if (prop == 'm' && oldVal != newVal) {
                    if (newVal == 1) {
                        h.getSourceData()[row].m2 = 0;
                        h.getSourceData()[row].c2 = 0;
                    } else {
                        h.getSourceData()[row].m2 = 1;
                        h.getSourceData()[row].c2 = 1;
                    }
                    self.actualizar_preciocoste(row);
                }
                if (prop == 'm2' && oldVal != newVal && source != 'auto') {
                    if (newVal == 1) {
                        h.getSourceData()[row].m = 0;
                    } else {
                        h.getSourceData()[row].m = 1;
                        h.getSourceData()[row].c2 = 0;
                    }
                    self.actualizar_preciocoste(row);
                }
                //Calculamos el total línea
                if ((prop == 'unidades' || prop == 'precio' || prop == 'descuentos' || prop == 'peso' || prop == 'c') && oldVal != newVal) {
                    self.actualizar_totallinea(row);
                }
                //Si cambiamos el totallinea, cambiamos el descuento
                if (prop == 'totallinea' && oldVal != newVal && source != 'auto') {
                    if (val('c') == 'P') {
                        var descuento = -(val('totallinea') / (val('precio') * val('peso')) - 1) * 100;
                    } else {
                        descuento = -(val('totallinea') / (val('precio') * val('unidades')) - 1) * 100;
                    }
                    h.getSourceData()[row].descuentos = descuento;
                }

                //Calculamos el precio de coste
                if ((prop == 'unidades' || prop == 'precio' || prop == 'descuentos' || prop == 'peso' || prop == 'c2' || prop == 'o' || prop == 'c' || prop == 'v' || prop == 'm' || prop == 'm3' || prop == 'mpl' || prop == 'pmp' || prop == 'usarpmp') && oldVal != newVal) {
                    self.actualizar_preciocoste(row);
                }

                //Calcular otros_costes de cada línea
                if ((prop == 'precio_coste' || prop == 'unidades') && oldVal != newVal) {
                    self.actualizar_otroscosteslinea();
                }

                if ((prop == 'taqueria_ids') && oldVal != newVal) {
                    //TODO Recalcular 'taqueria_precio'
                    self.actualizar_taqueria_precio(row);
                }

                //Cálculos en el PIE
                /*if (prop == 'unidades' && oldVal != newVal) {
                    self.actualizar_total_unidades();
                }
                if (prop == 'peso' && oldVal != newVal) {
                    self.actualizar_total_peso();
                }
                if ((prop == 'totallinea' || prop == 'r') && oldVal != newVal) {
                    self.actualizar_total_articulos();
                }
                if ((prop == 'peso' || prop == 'm2') && oldVal != newVal) {
                        self.actualizar_g18k();
                        self.actualizar_gmerma();
                   
                }
                if ((prop == 'mpl') && oldVal != newVal) {
                    self.actualizar_gmerma();
                }
                if ((prop == 'taqueria_ids' || prop == 'unidades') && oldVal != newVal) {
                    self.actualizar_taqueria();
                }*/


            });
        });

        self.$refs.lineas.field.hotInstance.addHook('afterSelectionEnd', function (row) {
            if (parseInt(self.app.config['mostrar_imagen_' + self.dbAdapter])) {
                let h = this;
                let articulo_id = h.getSourceData()[row].articulo_id;
                if (!articulo_id) return;
                if (typeof articulo_id == 'object') var a = articulo_id;
                else a = h.cachedItems.articulo_id[articulo_id] || {};

                if (self.win.children.length && self.win.children.some(ch => ch.window_name == 'popup_imagen')) {
                    let popupImagen = self.win.children.find(ch => ch.window_name == 'popup_imagen');
                    if (popupImagen.instance) popupImagen.instance.articulo = a;
                    else popupImagen.opts.articulo = a;
                } else {
                    self.app.openChildWindow('popup_imagen', self.win, {
                        backdrop: false,
                        resizable: true,
                        articulo: a
                    })
                }
            }
        });


        self.$refs.lineas.field.hotInstance.updateSettings({
            enterMoves: function (event) {
                let h = self.$refs.lineas.field.hotInstance;
                let sel = h.getSelectedLast();
                let row = sel[0];
                let prop = h.colToProp(sel[1]);
                let value = h.getSourceData()[row][prop];
                if ((prop == "ean13" || prop == "articulo_id") && value) {
                    return { row: 1, col: 0 };
                }
                if (prop != 'ean13' && prop != 'modelo_id' && !h.getSourceData()[row]['articulo_id']) {
                    if (!(self.mode == 'edit' || self.mode == 'new')) {
                        self.app.toast('Debes pulsar el botón Editar o Nuevo', 'error')
                    } else {
                        h.deselectCell();
                        self.app.openChildWindow('articulo_quickview', self.win, {
                            backdrop: true,
                            resizable: false,
                            props: {
                                initialMode: 'new',
                                initialFormData: {
                                    codigo: '',
                                    modelo_id: h.getSourceData()[row]['modelo_id'] || null
                                },
                                onSave(id, data) {
                                    h.setDataAtRowProp(row, 'articulo_id', id, 'newproduct');
                                    this.$emit('close');
                                },
                                onCancel() {
                                    this.$emit('close');
                                }
                            }
                        })
                    }
                }
                return { row: 0, col: 1 };
            }
        });

        self.actualizarTodo = self.$utils.misc.debounce(function (allRows = false) {
            if (allRows && self.formData.lineas) {
                self.formData.lineas.forEach(function (linea, row) {
                    self.actualizar_totallinea(row);
                    self.actualizar_preciocoste(row);
                    self.actualizar_taqueria_precio(row);
                    self.actualizar_pventa(row);
                });
            } else {
                self.filasPendientesActualizar.forEach(function (row) {
                    self.actualizar_totallinea(row);
                    self.actualizar_preciocoste(row);
                    self.actualizar_taqueria_precio(row);
                    self.actualizar_pventa(row);
                });
            }
            self.filasPendientesActualizar = [];

            self.actualizar_otroscosteslinea();
            /*self.actualizar_total_unidades();
            self.actualizar_total_peso();
            self.actualizar_total_articulos();
            self.actualizar_g18k();
            //self.actualizar_g24k_porcentaje(); //Lo hace con lo anterior
            self.actualizar_gmerma();
            self.actualizar_taqueria();
            self.actualizar_estadisticas_familias();*/
        }, 500);


        self.$on('loadItem', function () {
            self.actualizar_taqueria();
            self.actualizar_estadisticas_familias();
        })

    }
}